'use es6';

import * as DeviceTypes from 'feedback-schema/constants/devices';
const GRID_BREAKPOINT_SMALL = '544px';
const GRID_BREAKPOINT_MEDIUM = '768px';
const TABLET_WIDTH = parseInt(GRID_BREAKPOINT_MEDIUM, 10);
const PHONE_WIDTH = parseInt(GRID_BREAKPOINT_SMALL, 10);
const getDeviceType = width => {
  let deviceType = DeviceTypes.DESKTOP;
  if (width <= TABLET_WIDTH) {
    deviceType = DeviceTypes.TABLET;
  }
  if (width <= PHONE_WIDTH) {
    deviceType = DeviceTypes.MOBILE;
  }
  return deviceType;
};
export default getDeviceType;